// Core variables and mixins
@import "variables";
@import "../../../../../../node_modules/bootstrap/less/mixins";

// Reset and dependencies
@import "../../../../../../node_modules/bootstrap/less/normalize";
@import "../../../../../../node_modules/bootstrap/less/print";
@import "../../../../../../node_modules/bootstrap/less/glyphicons";

// Core CSS
@import "../../../../../../node_modules/bootstrap/less/scaffolding";
@import "../../../../../../node_modules/bootstrap/less/type";
@import "../../../../../../node_modules/bootstrap/less/code";
@import "../../../../../../node_modules/bootstrap/less/grid";
@import "../../../../../../node_modules/bootstrap/less/tables";
@import "../../../../../../node_modules/bootstrap/less/forms";
@import "../../../../../../node_modules/bootstrap/less/buttons";

// Components
@import "../../../../../../node_modules/bootstrap/less/component-animations";
@import "../../../../../../node_modules/bootstrap/less/dropdowns";
@import "../../../../../../node_modules/bootstrap/less/button-groups";
@import "../../../../../../node_modules/bootstrap/less/input-groups";
@import "../../../../../../node_modules/bootstrap/less/navs";
@import "../../../../../../node_modules/bootstrap/less/navbar";
@import "../../../../../../node_modules/bootstrap/less/breadcrumbs";
@import "../../../../../../node_modules/bootstrap/less/pagination";
@import "../../../../../../node_modules/bootstrap/less/pager";
@import "../../../../../../node_modules/bootstrap/less/labels";
@import "../../../../../../node_modules/bootstrap/less/badges";
@import "../../../../../../node_modules/bootstrap/less/jumbotron";
@import "../../../../../../node_modules/bootstrap/less/thumbnails";
@import "../../../../../../node_modules/bootstrap/less/alerts";
@import "../../../../../../node_modules/bootstrap/less/progress-bars";
@import "../../../../../../node_modules/bootstrap/less/media";
@import "../../../../../../node_modules/bootstrap/less/list-group";
@import "../../../../../../node_modules/bootstrap/less/panels";
@import "../../../../../../node_modules/bootstrap/less/responsive-embed";
@import "../../../../../../node_modules/bootstrap/less/wells";
@import "../../../../../../node_modules/bootstrap/less/close";

// Components w/ JavaScript
@import "../../../../../../node_modules/bootstrap/less/modals";
@import "../../../../../../node_modules/bootstrap/less/tooltip";
@import "../../../../../../node_modules/bootstrap/less/popovers";
@import "../../../../../../node_modules/bootstrap/less/carousel";

// Utility classes
@import "../../../../../../node_modules/bootstrap/less/utilities";
@import "../../../../../../node_modules/bootstrap/less/responsive-utilities";


.btn-fire {
  .button-variant(#b20e09; #f14e07; #f14e07);
}
.text-large{
  font-size: @font-size-large;
}
@media (min-width: @screen-sm){
  .navbar-nav>li>a {
    padding: 16px 10px;
  }
}
body {
  padding-top: 70px;
  padding-bottom: 30px;
}

.theme-dropdown .dropdown-menu {
  position: static;
  display: block;
  margin-bottom: 20px;
}

.theme-showcase > p > .btn {
  margin: 5px 0;
}

.theme-showcase .navbar .container {
  width: auto;
}

.control-label {
  text-align: left !important;
}

.center {
  text-align: center;
}
.table.inverse-margin{
  margin-bottom: 0;
  margin-top: 10px;
}
#categoryList hr{
  margin-top: 5px;
  margin-bottom: 5px;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 10px 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.label-as-badge {
  border-radius: 1em;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 80%;
}

a.navbar-brand.active {
  color: #FFF;
}
.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
}
.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
  margin-top: 0;
}
/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
}
.footer > .container{
  margin-top: 20px
}
.truncate-link{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.index_custom_col1{
  width: 30px
}
.index_custom_col2{
  width: 90px
}